<template>
  <div>
    <div id="l12mt" class="card card-custom gutter-b">
      <div class="card-header border-0 pb-0">
        <h3 class="card-title">Traffic last 365 days</h3>
      </div>
      <div class="card-body py-0">
        <apexchart
          v-if="isReady"
          type="area"
          height="400"
          ref="chart"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Last12MonthTraffic',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
  },
  data() {
    return {
      transactions: [],
      months: [],
      chartOptions: {
        chart: {
          type: 'area',
          height: 400,
          zoom: {
            autoScaleXaxis: true,
            autoScaleYaxis: true,
          },
        },
        annotations: {
          yaxis: [],
          xaxis: [],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: new Date(this.traffic[this.traffic.length - 1].date).getTime(),
          tickAmount: 6,
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },
      series: [
        {
          name: 'Trafic',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  mounted() {
    this.traffic.forEach((transaction) => {
      this.series[0].data.push([
        new Date(transaction.date).getTime(),
        parseFloat(transaction.amount),
      ])
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {},
}
</script>
