<template>
  <div style="height: 80vh; overflow-y: auto;">
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pb-0">
        <h3 class="card-title">Cumulative</h3>
      </div>
      <div class="card-body py-0">
        <WeeklyHistoryCumulative
          v-if="isCumulativeWeeksDataReady"
          :traffic="cumulativeWeeksData"
        ></WeeklyHistoryCumulative>
        <div v-else class="col-12 text-center pb-5">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>

    <div class="separator separator-dashed my-5"></div>

    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pb-0">
        <h3 class="card-title">Full</h3>
      </div>
      <div class="card-body py-0">
        <WeeklyHistoryFull
          v-if="isFullWeeksDataReady"
          :traffic="fullWeeksData"
          :this-week-total="thisWeekTotal"
        ></WeeklyHistoryFull>
        <div v-else class="col-12 text-center pb-5">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addDays from 'date-fns/addDays'
import { mapGetters } from 'vuex'
import WeeklyHistoryFull from './WeeklyHistoryFull'
import WeeklyHistoryCumulative from './WeeklyHistoryCumulative'
import ApiService from '@/core/services/api.service'
import { format } from 'date-fns'

export default {
  name: 'WeeklyHistory',
  components: {
    WeeklyHistoryFull,
    WeeklyHistoryCumulative,
  },
  props: {
    scale: {
      type: Number,
    },
    thisWeekTotal: {
      type: Number,
    },
  },
  data() {
    return {
      days: [],

      cumulativeWeeksData: [],
      fullWeeksData: [],

      isCumulativeWeeksDataReady: false,
      isFullWeeksDataReady: false,
    }
  },
  computed: { ...mapGetters(['currentUserCompanyOib', 'currentUserCompany']) },
  mounted() {
    let i
    for (i = 0; i < this.scale; i++) this.days.push(addDays(new Date(), -i))

    this.calculateFull()
    this.calcCumulative()
  },
  methods: {
    calculateFull() {
      this.days.forEach((day, key) => {
        this.fullWeeksData.push({
          day: format(day, 'dd.MM.yyyy'),
          dayFormat: day,
          value: 0,
        })

        ApiService.get(
          'company_transactions',
          `?oib=${this.currentUserCompanyOib}&date=${format(
            day,
            'yyyy-MM-dd'
          )}&order[createdAt]=desc&pagination=false`
        ).then(({ data }) => {
          data['hydra:member'].forEach(({ total, date }) => {
            const row = this.fullWeeksData.findIndex(
              (day) => day.day === format(new Date(date), 'dd.MM.yyyy')
            )
            this.fullWeeksData[row].value += parseFloat(total)
          })

          if (this.fullWeeksData.length === key + 1) {
            setTimeout(() => {
              this.isFullWeeksDataReady = true

              this.calcCumulative()
            }, 1000)
          }
        })
      })
    },
    calcCumulative() {
      this.days.forEach((day, key) => {
        this.cumulativeWeeksData.push({
          day: format(day, 'dd.MM.yyyy'),
          dayFormat: day,
          value: 0,
        })

        let startDayTime = format(day, "yyyy-MM-dd'T'HH:mm")
        startDayTime = `${startDayTime.slice(0, 10)}T00:00`
        const currentDayTime = format(day, "yyyy-MM-dd'T'HH:mm")

        ApiService.get(
          `daily-cumulative-transactions?companyId=${this.currentUserCompany.replace('/api/v1/companies/','')}&after=${startDayTime}&before=${currentDayTime}`,
        ).then(({ data }) => {
          data.forEach(({ sum, createdAt }) => {
            const row = this.cumulativeWeeksData.findIndex(
              (day) => day.day === format(new Date(createdAt), 'dd.MM.yyyy')
            )

            this.cumulativeWeeksData[row].value += parseFloat(sum)
 
          })

          if (this.cumulativeWeeksData.length === key + 1) {
            setTimeout(() => {
              this.isCumulativeWeeksDataReady = true
            }, 10000)
          }
        })
      })
    },
  },
}
</script>
